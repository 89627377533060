<script setup>
import {computed, ref} from 'vue'

const advertisementQuantity = ref(1000)
const subscriptionPeriod = ref()
const form = ref({
  name: '',
  email: '',
  phone: '',
  ico: '',
})

const incrementQuantity = () => {
  advertisementQuantity.value += 1
}

const decrementQuantity = () => {
  if(advertisementQuantity.value > 1) {
    advertisementQuantity.value -= 1
  } else {
    advertisementQuantity.value = 1
  }
}

const calculatePricePerMonth = (discount = 0) => {
  let price = 0
  // Calculate price based on the advertisement quantity
  if(advertisementQuantity.value === 1) {
    price = 2000
  } else if(advertisementQuantity.value > 1) {
    // First advertisement is for constant 2000 credits.
    price = Math.round((advertisementQuantity.value - 1) * 15 + 2000)
  } else {
    price = 0
  }
  return Math.round(price * (1 - discount / 100))
}

const calculatePricePerMonths = (months, discount = 0) => {
  if(months < 1) {
    return 0
  }
  return Math.round(calculatePricePerMonth(discount) * months)
}

const calculatePricePerDay = (discount = 0) => {
  return Math.round(calculatePricePerMonth(discount) / 30)
}

const calculateDiscount = (months) => {
  if(months === 6) {
    return 5
  } else if(months === 12) {
    return 10
  } else {
    return 0
  }
}

const creditQuantity = computed(() => {
  let months = parseInt(subscriptionPeriod.value)
  if (months === undefined) {
    return 0
  } else if (months < 1) {
    return 0
  } else if (months >= 1) {
    return calculatePricePerMonths(months, calculateDiscount(months))
  }
})

const subscriptionPeriodLabel = computed(() => {
  let months = parseInt(subscriptionPeriod.value)
  if(months === 1) {
    return '1 měsíc'
  } else if(months === 6) {
    return `${months} měsíce`
  } else if(months === 12) {
    return `${months} měsíců`
  } else {
    return 'zvolte'
  }
})

const allowSubmit = computed(() => {
  return subscriptionPeriod.value && advertisementQuantity.value > 0
})

// const calculateDaysLeft = () => {
//   // Calculate days left based on the advertisement quantity
//   return Math.round(creditQuantity.value / calculatePricePerDay())
// }
//
// const calculateExpirationDate = () => {
//   const today = new Date();
//   today.setDate(today.getDate() + calculateDaysLeft());
//   return today.toLocaleDateString('cs-CZ')
// }
</script>

<template>
  <div>
    <div class="bg-gray-200 rounded-4 p-2 p-md-3 mt-0 mt-lg-3">
      <h3>Kalkulace ceny</h3>
      <div class="row mb-3">
        <div class="col-5">
          <h4 class="mb-0">Počet inzerátů</h4>
          <small class="text-gray-600">Máte <strong class="text-gray-800">1000</strong> zveřejněných inzerátů.</small>
        </div>
        <div class="col-7">
          <div class="input-group" style="max-width: 300px;">
            <div class="input-group-prepend">
              <button @click="decrementQuantity" class="btn btn-outline-secondary py-2" type="button">
                -
              </button>
            </div>
            <input v-model="advertisementQuantity" type="number" class="form-control text-center" min="1" max="100000">
            <div class="input-group-append">
              <button @click="incrementQuantity"  class="btn btn-outline-secondary py-2" type="button">
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-5">
      <h4 class="mb-4">Vyberte aktivační dobu</h4>
      <div class="row mb-3">
        <div class="col-5">
          <h4 class="mb-0">Cena za den</h4>
          <small class="text-gray-600">Počet inzerátů {{ advertisementQuantity }} za 1 den</small>
        </div>
        <div class="col-4">
          {{ calculatePricePerDay() }} kreditů
        </div>
        <div class="col-3">
          1 kredit = 1 Kč
        </div>
      </div>
      <hr class="my-5">
      <div class="row mb-3">
        <div class="col-5">
          <h4 class="mb-0">Cena za měsíc</h4>
          <small class="text-gray-600">Počet inzerátů {{ advertisementQuantity }} za 30 dní</small>
        </div>
        <div class="col-4">
          {{ calculatePricePerMonths(1) }} kreditů
        </div>
        <div class="col-3">
          <div class="form-check form-check-inline">
            <input v-model="subscriptionPeriod" value="1" class="form-check-input" type="radio" name="subscription_period" id="for_month">
            <label class="form-check-label" for="for_month">vybrat</label>
          </div>
        </div>
      </div>
      <hr class="my-5">
      <div class="row mb-3">
        <div class="col-5">
          <h4 class="mb-0">Cena za 6 měsíců</h4>
          <small class="text-gray-600">Počet inzerátů {{ advertisementQuantity }} za 180 dní <strong class="text-primary">(sleva {{ calculateDiscount(6) }}%)</strong></small>
        </div>
        <div class="col-4">
          {{ calculatePricePerMonths(6, calculateDiscount(6)) }} kreditů
        </div>
        <div class="col-3">
          <div class="form-check form-check-inline">
            <input v-model="subscriptionPeriod" value="6" class="form-check-input" type="radio" name="subscription_period" id="for_6_month">
            <label class="form-check-label" for="for_6_month">vybrat</label>
          </div>
        </div>
      </div>
      <hr class="my-5">
      <div class="row mb-3">
        <div class="col-5">
          <h4 class="mb-0">Cena za rok</h4>
          <small class="text-gray-600">Počet inzerátů {{ advertisementQuantity }} za 360 dní <strong class="text-primary">(sleva {{ calculateDiscount(12) }}%)</strong></small>
        </div>
        <div class="col-4">
          {{ calculatePricePerMonths(12, calculateDiscount(12)) }} kreditů
        </div>
        <div class="col-3">
          <div class="form-check form-check-inline">
            <input v-model="subscriptionPeriod" value="12" class="form-check-input" type="radio" name="subscription_period" id="for_year">
            <label class="form-check-label" for="for_year">vybrat</label>
          </div>
        </div>
      </div>
    </div>
    <h4 class="mt-4 mb-0">Objednávka Premium profilu</h4>
    <p>Po odeslání objednávky vás budeme kontaktovat. Upřesníme si nahrání Bannerů, Loga, termín aktivace Premium profilu, počty inzerátů a další detaily.</p>

    <h4 class="mt-4 mb-2">Souhrn</h4>
    <table class="table table-bordered mb-4">
      <tbody>
        <tr>
          <td>
            Aktivační doba
          </td>
          <td>
            <strong>{{ subscriptionPeriodLabel }}</strong><br/>
            <span class="text-gray-500">Aktivační doba se může měnit v závislosti na počtu inzerátů.</span>
          </td>
        </tr>
        <tr>
          <td>
            Počet inzerátů
          </td>
          <td>
            <strong>{{ advertisementQuantity > 0 ? advertisementQuantity : 0 }}</strong>
          </td>
        </tr>
        <tr>
          <td>
            Sleva
          </td>
          <td>
            <strong>{{ calculateDiscount(parseInt(subscriptionPeriod)) }}%</strong>
          </td>
        </tr>
        <tr>
          <td>
            Vaše cena bez DPH
          </td>
          <td>
            <strong>{{ creditQuantity }} Kč</strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="mb-3">
      <label for="name" class="form-label">Kontaktní osoba</label>
      <input v-model="form.name" type="text" class="form-control" id="name" placeholder="Jméno a Příjmení">
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">E-mail</label>
      <input v-model="form.email" type="text" class="form-control" id="email" placeholder="Váš e-mail">
    </div>
    <div class="mb-3">
      <label for="phone" class="form-label">Telefon</label>
      <input v-model="form.phone" type="text" class="form-control" id="phone" placeholder="Váš telefon">
    </div>
    <div class="mb-3">
      <label for="phone" class="form-label">IČO</label>
      <input v-model="form.ico" type="text" class="form-control" id="phone" placeholder="Vaše IČO">
    </div>
    <div class="my-3">
      <small class="text-gray-600">
        Odesláním objednávky souhlasíte s
        <a href="/clanky/jak-funguje-web#obchodni-podminky" target="_blank">VOP</a>
        a <a href="/clanky/jak-funguje-web#ochrana-soukromi" target="_blank">GDPR</a>.
      </small>
    </div>
    <div class="d-flex justify-content-start gap-5">
      <button :disabled="!allowSubmit" type="button" class="btn btn-alias-submit btn-lg btn-gray">
        Objednat Premium profil
      </button>
      <div class="d-block">
        <small class="d-block text-gray-600 text-uppercase">Vaše Cena bez DPH</small>
        <strong v-if="allowSubmit">{{ creditQuantity }} Kč</strong>
        <strong v-else>Zvolte délu předplatného a počet inzerátů</strong>
      </div>
    </div>
  </div>
</template>
