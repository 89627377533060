import { Tab } from 'bootstrap';
import { createApp } from 'vue'
import CalculatorForm from "~/src/components/premium/CalculatorForm.vue";

export const UserProfileShowView = {

  init() {
    const settingsTab = new Tab(document.querySelector('#nav-settings-tab'))
    const personalTab = new Tab(document.querySelector('#nav-offers-tab'))
    if ((location.hash !== null && location.hash === '#nav-settings-tab')) {
      settingsTab.show()
    } else {
      personalTab.show()
    }

    document.querySelector('.btn-alias-submit').addEventListener('click', function (e ) {
      e.preventDefault();
      let button = e.target;
      button.disabled = true;
      const form = document.querySelector(button.dataset.form);
      if(form) {
        form.requestSubmit();
        const submitButton = form.querySelector("button[type='submit']");
        submitButton.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          button.disabled = false;
        }, 2000)
      } else {
        console.error('Form not found');
      }
    });

    // Inits Premium calculator.
    createApp(CalculatorForm, { action: 'create' })
      .mount('#form-calculator-premium')
  },
}
